import {FC, memo, useMemo} from 'react';
import {Link, NavigateOptions, useNavigate} from 'react-router-dom';

import {OrderType} from '../../../../../interfaces/enums';
import {ServiceListItem} from '../../../../../interfaces/service';
import {setCheckedServices} from '../../../../../redux/reducers/service-preview-reduser';
import {useAppDispatch} from '../../../../../redux/store';
import {SERVICE_ABONEMENT, SERVICE_PAGE, SERVICE_PREVIEW} from '../../../../../shared/constants/path';
import {kindLabelView, priceTitle} from '../../../../../shared/constants/service/kind';
import {getPriceLabel} from '../../../../../shared/utils/converter-price';
import {useGetDayInfo} from '../../../../../shared/utils/get-day-info';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import {getServiceWorkingTime} from '../../../../../shared/utils/service/get-service-working-time';
import {useServicePrice} from '../../../../../shared/utils/service/use-service-price';
import {getParticipantsLabel} from '../../../../../shared/utils/transform-text-label';
import IconCount from '../../../../svg-icon-components/icon-count';
import IconDollar from '../../../../svg-icon-components/icon-dollar';
import IconTime from '../../../../svg-icon-components/icon-time';
import {Address} from '../../components/address/address';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {
	ServiceInfoIconTooltipWrapper
} from '../../components/service-info-icon-tooltip-wrapper/service-info-icon-tooltip-wrapper';
import {ServiceName} from '../../components/service-name/service-name';
import {TextWithIcon} from '../../components/text-with-icon/text-with-icon';
import * as SC from './other-services.style';

export interface ServiceCardProps {
	service: ServiceListItem;
	closeHover?: boolean;
	isAdminPage?: boolean
}

export const OtherServices: FC<ServiceCardProps> = memo(({service, isAdminPage, closeHover}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const goToServiceCard = (options?: NavigateOptions) => {
		const url = `${SERVICE_PAGE}?service_id=${service.id}`;
		navigate(url, options);
	};
	const goToServiceCardMap = () => {
		goToServiceCard({state: {map: true}});
	};
	const forAbonement = 'Стоимость пробного занятия';
	const onClickLogoButton = () => {
		handleScrollToTop();
		if (isAdminPage) {
			dispatch(setCheckedServices(service));
			const url = `${SERVICE_PREVIEW}?service_id=${service.id}`;
			navigate(url);
			return;
		}
		const url = `${SERVICE_PAGE}?service_id=${service.id}`;
		navigate(url);
	};
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);

	const orgLogo = useMemo(() => service.org_info.logo.find(image => image.info.size_label === '1:1')?.info.image_link, [service.id]);
	const serviceLogo = useMemo(() => service.logo.find(image => image.info.size_label === '16:9')?.info.image_link, [service.id]);

	return (
		<CardContainer>
			<Logo notIsHovered={closeHover} orgLogo={orgLogo} serviceLogo={serviceLogo}
				  serviceName={service.service_type} orgName={service.org_info.org_name}
				  onClick={onClickLogoButton} isAdmin={isAdminPage}/>
			<SC.TextContentWrapper isAdminPage={isAdminPage} active={service.active}>
				<ServiceInfoIconTooltipWrapper service={service} onGoToMap={goToServiceCardMap}/>
				<ServiceName serviceName={service.service_type}/>
				<Address platformInfo={service.platform_info} goToServiceCardMap={goToServiceCardMap}/>
				{service.order_policy.allows !== OrderType.abonementOnly ?
					<TextWithIcon icon={<IconDollar/>} description={priceTitle(service.service_kind)}
								  textRight={priceInfo ? getPriceLabel(priceInfo.minPricePerOrder, priceInfo.hasPriceChanges) : 'Не определена'}/>
					: <SC.AbonementPriceWrapper>
						<SC.IconPriceWrapper>
							<IconDollar/>
							<span>{forAbonement}</span>
						</SC.IconPriceWrapper>
						{service.order_policy.trial_order === null 
						? <Link to={SERVICE_ABONEMENT.replace(':kind', service.service_kind).replace(':service_id', service.id)}>Предложения</Link>
						: (service.order_policy.trial_order === 0 ? 'Бесплатно' : `${(service.order_policy.trial_order / 100).toFixed(2)} BYN`)}
					</SC.AbonementPriceWrapper>}
				<TextWithIcon icon={<IconTime/>} textRight={'Сегодня'}
							  description={getServiceWorkingTime(useGetDayInfo(new Date(), service.schedule).dayInfo)}/>
				<TextWithIcon icon={<IconCount/>} description={kindLabelView(service.service_kind)}
							  textRight={getParticipantsLabel(service.max_participants, service.service_kind)}/>
				<SC.Description>{service.description}</SC.Description>
			</SC.TextContentWrapper>
		</CardContainer>
	);
});
