import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setSingleOrganization} from '../../reducers/organizations-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetOrganizationByName = createAsyncThunk(
	'fieldSlice/fetchGetOrganizationByName',
	async (organizationName: string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));

			const response = await SearchPlatformAPI.getOrganizationByName({org_name: organizationName});

			if (response.status === requestCodes.success) {
				dispatch(setSingleOrganization({data: response.data}));
				return {status: response.status, data: response.data};
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setSingleOrganization({data: null}));
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		} finally {
			dispatch(setShowLoader({isShowLoader: false}));
		}
	},
);
