import {FC, useRef} from 'react';

import {ServiceListItem} from '../../../interfaces/service';
import {ButtonGroupSettingService} from '../../buttons';
import {OtherServices} from '../card-service-new/cards/other-services/other-services';
import * as SC from './card-service.style';

interface CardServiceProps {
	service: ServiceListItem;
	onUpdate: () => void;
}

export const CardService: FC<CardServiceProps> = ({service, onUpdate}) => {
	const elementRef = useRef<HTMLDivElement>(null);

	// TODO: remove OrganizationCardModal from here
	return (
		<SC.ServiceWrapper ref={elementRef}>
			<OtherServices key={service.id} service={service} isAdminPage/>
			<ButtonGroupSettingService service={service} onUpdate={onUpdate}/>
		</SC.ServiceWrapper>
	);
};

