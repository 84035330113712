import {Dispatch} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {setIsShowMessage, setMessageUser, setShowLoader} from '../../redux/reducers/user-data-reducer';
import {
	dataSendingError409, errorServer500, invalidRequestFormat400, invalidUrlAddress404, messageErrServer,
	unAuthorized401
} from '../constants/message-user';
import {requestCodes} from '../constants/request-codes';

interface HandleAxiosErrorType {
	error: any,
	dispatch: Dispatch,
	rejectWithValue: any
}

export const handleAxiosError = ({error, dispatch, rejectWithValue}: HandleAxiosErrorType) => {
	const messageUser = {titleMessage: '', textMessage: '', action: 'close', btnTitle: 'Ок', isErrorMessage: true};

	dispatch(setShowLoader({isShowLoader: false}));
	if (isAxiosError(error)) {
		if (error.response === undefined) {
			console.log(error);
			return;
		}

		if (typeof error.response?.data === 'string') {
			dispatch(setMessageUser({messageUser, textMessage: error.response?.data}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			console.log(error.response?.data);
			return;
		}

		switch (error.response?.status) {
			case requestCodes.badRequest:
				dispatch(setMessageUser({
					messageUser,
					titleMessage: invalidRequestFormat400,
					textMessage: error.response?.data.message,
					descriptionMessage: error.response?.data.description,
				}));
				console.log(error.response?.data);
				break;

			case requestCodes.unauthorized:
			case requestCodes.forbidden:
				dispatch(setMessageUser({
					messageUser,
					textMessage: error.response.data.message,
					titleMessage: unAuthorized401,
					descriptionMessage: error.response?.data.description,
				}));
				console.log(error.response?.data);
				break;

			case requestCodes.notFound:
				dispatch(setMessageUser({
					messageUser,
					titleMessage: invalidUrlAddress404,
					textMessage: error.response?.data.message,
					descriptionMessage: error.response?.data.description,
				}));
				console.log(error.response?.data);
				break;

			case requestCodes.conflict:
				dispatch(setMessageUser({
					messageUser,
					textMessage: error.response.data.message,
					titleMessage: dataSendingError409,
					descriptionMessage: error.response?.data.description,
				}));
				console.log(error.response?.data);
				break;

			case requestCodes.serverError:
				dispatch(setMessageUser({
					messageUser,
					textMessage: error.response.data.message,
					titleMessage: errorServer500,
				}));
				console.log(error.response?.data);
				break;

			default:
				return;
		}

		dispatch(setIsShowMessage({isShowMessage: true}));
	} else {
		dispatch(setMessageUser({messageUser: messageErrServer}));
		dispatch(setIsShowMessage({isShowMessage: true}));
		return rejectWithValue('This is not an Axios error');
	}
};
