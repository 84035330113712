import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const PlatformCard = styled.div`
	display: flex;
	height: fit-content;
	margin-bottom: 20px;
	background-color: var(--grey);
	border-radius: 8px;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		padding: 10px 10px 20px ;
	}
`;
export const PlatformCardLogoWrapper = styled.div`
	flex-shrink: 0;
	border-radius: 8px;
	width: 280px;
	min-height: 100%;
	position: relative;
	border: 1px solid var(--gray-dark);
	transition: 0.5s;

	&:hover {
		button {
			display: block;
		}
		.logo{
			filter: brightness(0.6);
		}
	}

	@media screen and (max-width: 800px) {
		width: 100%;
		height: min(230px,100%);
	}
`;

export const LogoDesktop = styled(Link)`
	@media screen and (max-width: 800px) {
		display: none;
	}
`;
export const LogoMobile = styled(Link)`
	display: none;
	@media screen and (max-width: 800px) {
		display: block;
	}
`;
export const PlatformCardLogo = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 8px;
	max-height: 401px;
	object-fit: cover;
	cursor: pointer;
	transition: 0.7s;

	&:hover {
		filter: brightness(0.6);
	}
`;
export const PlatformCardMainInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	min-width: 245px;
	margin-left: 28px;
	padding: 12px 0;

`;
export const PlatformCardTitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 1.385em;
`;
export const PlatformCardAdditionalInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 12px 12px 0 0;
	position: relative;
	justify-content: flex-start;
	gap: 16px
`;
export const PlatformCardInfoBlockDescription = styled.p`
	overflow-y: auto;
	font-size: 14px;
	line-height: 1.385em;
	max-height: 200px;

	scrollbar-color: #9f9f9f #eaeaea;
	scrollbar-width: thin;
`;
export const PlatformCardOrganizationLogo = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 50%;

	object-fit: cover;
	cursor: pointer;
`;
