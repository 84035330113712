import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import imgSport from '../../../assets/img/allSport.png';
import {SportRu} from '../../../interfaces/enums';
import {SportType} from '../../../interfaces/interfaces';
import {setFilterSport} from '../../../redux/reducers/sport-reducer';
import {useAppSelector} from '../../../redux/selectors/hook';
import {allSportTypesSelector} from '../../../redux/selectors/sport-state-selectors';
import {PLAY_FIELDS} from '../../../shared/constants/path';
import {handleScrollToTop} from '../../../shared/utils/scroll-to-top';
import {Button} from '../../buttons';
import {GalleryStartPage} from '../../gallery-start-page';
import {Section} from '../../section/section';
import styles from './section-slider-sport.module.css';


export const SectionSliderSport = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const allSportsTypes = useAppSelector(allSportTypesSelector);

	const [arrImgSport, setArrImgSport] = useState<SportType[]>([]);

	useEffect(() => {
		if (allSportsTypes && allSportsTypes.length) {
			const arrSrcImg = allSportsTypes.filter((sport: SportType) => sport.logo);
			setArrImgSport(arrSrcImg);
		}
	}, [allSportsTypes]);

	const navigateFields = () => {
		dispatch(setFilterSport({filter: SportRu.all}));
		handleScrollToTop();
		navigate(PLAY_FIELDS);
	};

	return (
		<Section className={styles.section_slider_sport}>
			<h2 className={styles.title}>Выберите вид спорта</h2>
			{arrImgSport.length > 0 && <GalleryStartPage srcImg={arrImgSport} />}
			{!arrImgSport.length && <img className={styles.img_zero} src={imgSport} alt="img" title="img" />}
			<Button className={styles.submit_button} label='Смотреть залы' onClick={navigateFields}/>
		</Section>
	);
};
