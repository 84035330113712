import {createSlice} from '@reduxjs/toolkit';

import {SportType} from '../../interfaces/interfaces';
export interface SportState {
	allCity: string[];
	filterSport: string;
	allServicesTypes: string[];
	allSportTypes: SportType[];
	arrSportString: string[];
	arrServiceTypeString: string[];
}

const initialState: SportState = {
	allCity: [],
	filterSport: 'Все виды',
	allServicesTypes: [],
	allSportTypes: [],
	arrSportString: [],
	arrServiceTypeString: [],
};

export const sportSlice = createSlice({
	name: 'sportSlice',
	initialState,
	reducers: {
		setAllCity: (state: SportState, action) => {
			state.allCity = [...action.payload.data];
		},
		setArrServiceTypeString: (state: SportState, action) => {
			state.arrServiceTypeString = action.payload.data;
		},
		setArrSportString: (state: SportState, action) => {
			state.arrSportString = action.payload.data;
		},
		setFilterSport: (state: SportState, action) => {
			state.filterSport = action.payload.filter;
		},
		setAllSportType: (state: SportState, action) => {
			state.allSportTypes = [...action.payload.data];
		},

		setServicesTypes: (state: SportState, action) => {
			state.allServicesTypes = action.payload.data;
		},

		addSportType: (state: SportState, action) => {
			state.allSportTypes = [...state.allSportTypes, action.payload.data];
		},

		deleteSportType: (state: SportState, action) => {
			state.allSportTypes = state.allSportTypes.filter((el) => el.sport !== action.payload);
		},

		updateSportType: (state: SportState, action) => {
			state.allSportTypes = state.allSportTypes.map((el) => {
				if (el.sport === action.payload.sport_type) {
					return {
						sport: action.payload.new_name,
						logo: action.payload.new_logo,
					};
				}

				return el;
			});
		},
	},
});

export const {
	setAllCity,
	setArrServiceTypeString,
	setArrSportString,
	setFilterSport,
	setAllSportType,
	setServicesTypes,
	addSportType,
	updateSportType,
	deleteSportType,
} = sportSlice.actions;
