export enum Issuer {
	org = 'organization',
	platf = 'platform',
	serv = 'service',
	sport = 'sport',
}

export enum ServiceTypeKind {
	rent = 'Rent', // Аренда зала
	inventory = 'Inventory', // Аренда инвентаря
	event = 'Event', // Мероприятие
	tournament = 'Tournament', // Турнир
	workout = 'Workout', // Секция, тренировка
}

export enum SportRu {
	tableTennis = 'Настольный теннис',
	tennis = 'Теннис',
	squash = 'Сквош',
	volleyball = 'Волейбол',
	football = 'Футбол',
	swimmingPool = 'Водное поло',
	all = 'Все виды',
}

export enum UserRole {
	viewer = 'Viewer',
	admin = 'Admin',
	moderator = 'Moderator',
	adminSite = 'OrganizationOwner',
	coach = 'Coach',
	unknown = 'Unknown'
}

export enum StatusAction {
	update = 'update',
	create = 'create',
}

export const LinksAdminRoom = {
	global: 'Админ',
	organizations: 'Клуб',
	platforms: 'Зал',
	users: 'Пользователи',
	orders: 'Заказы',
	cities: 'Города',
	admin_page: 'Подтверждение заявок',
	statistic: 'Статистика',
	sportKinds: 'Виды спорта',
	serviceTypes: 'Типы услуг',
	services: 'Услуги',
	addDependency: 'Добавить связи',
	createPlatform: 'Создать зал',
	createService: 'Создать <br/> услугу',
	allPlatform: 'Все залы',
	allWorkouts: 'Все <div>тренировки</div>',
	allInventories: 'Весь <div>инвентарь</div>',
	rent: 'Все <div class="nowrap">спортивные залы</div>',
	allTournaments: 'Все <div>турниры</div>',
	allEvents: 'Все <div>Спортивное событие</div>',
	workPlatform: 'Рабочий зал',
	allOrgs: 'Зарегистрированные клубы',
	orgRegistration: 'Создать клуб',
	allUsers: 'Все зарегистрированные пользователи',
	allClients: 'Список всех клиентов',
	allModerators: 'Список модераторов',
	allBlacklisted: 'Черный список пользователей',
	createAbonement: 'Создать абонемент',
	abonements: 'Абонементы',
};

export enum OrderType {
	view = 'View', // Не для заказа
	request = 'Request', // Через запись
	book = 'Book', // Мгновенная бронь
	abonementOnly = 'AbonementOnly', // Только для абонементов
}

export enum OrderServerStatus {
	Confirmed = 'Confirmed',
	Pending = 'Pending',
	WaitApproval = 'WaitApproval',
	Process = 'Process',
	Success = 'Success',
	Closed = 'Closed',
	Ignored = 'Ignored',
	WaitConfirmation = 'WaitConfirmation',
	IgnoredByPlatform = 'IgnoredByPlatform',
}

export enum OrderStatus {
	Pending = 'В ожидании',
	WaitApproval = 'На рассмотрении',
	Process = 'В процессе',
	Success = 'Завершен',
	Closed = 'Отменен',
	Ignored = 'Проигнорирован',
	WaitConfirmation = 'На подтверждении',
	IgnoredByPlatform = 'Не подтвержден',
}


