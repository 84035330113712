import {ServiceTypeKind} from '../../../interfaces/enums';
import {SERVICES} from '../path';

export const SERVICES_NAV_LINK_CONFIG = [
	{
		label: 'Тренировки',
		kind: ServiceTypeKind.workout,
		value: `${SERVICES}/${ServiceTypeKind.workout.toLocaleLowerCase()}`,
	},
	{
		label: 'Турниры',
		kind: ServiceTypeKind.tournament,
		value: `${SERVICES}/${ServiceTypeKind.tournament.toLocaleLowerCase()}`,
	},
	{
		label: 'Снаряжение и инвентарь',
		kind: ServiceTypeKind.inventory,
		value: `${SERVICES}/${ServiceTypeKind.inventory.toLocaleLowerCase()}`,
	},
	{
		label: 'Аренда спортивных залов',
		kind: ServiceTypeKind.rent,
		value: `${SERVICES}/${ServiceTypeKind.rent.toLocaleLowerCase()}`,
	},
	{
		label: 'Спортивные события',
		kind: ServiceTypeKind.event,
		value: `${SERVICES}/${ServiceTypeKind.event.toLocaleLowerCase()}`,
	},
];
