import {FC, RefObject, useEffect, useRef} from 'react';
import {useDropzone} from 'react-dropzone';

import {UniqueImage} from '../images/index.type';
import * as SC from './window-select-images.style';

interface Props {
	providedImages?: UniqueImage[]
	closeWindow: () => void;
	clickSelectPhoto: (link: string) => void;
	inputRef: RefObject<HTMLInputElement>;
	onDrop: (acceptedFiles: File[]) => void;
}

export const WindowSelectImages: FC<Props> = ({
	providedImages, closeWindow, clickSelectPhoto, inputRef, onDrop,
											  }) => {
	const imageLinks = providedImages?.map(image => image.info.image_link);
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
				closeWindow();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [closeWindow]);

	const {getRootProps, getInputProps} = useDropzone({
		onDrop,
		accept: {
			'image/*': [],
		},
		multiple: false,
	});

	return (
		<SC.Container>
			<SC.Window ref={elementRef}>
				<SC.ImagesWrapper>
					{imageLinks?.map((link, index) => (
						<SC.Img onClick={() => clickSelectPhoto(link)} key={link} src={link} alt={`photo-${index}`}/>
					))}
				</SC.ImagesWrapper>
				<SC.FormButton ref={inputRef} {...getRootProps()}>
					<input {...getInputProps()} />
					<SC.TextInput>Выбрать из устройства</SC.TextInput>
				</SC.FormButton>
				<SC.CloseBtn onClick={closeWindow}>X</SC.CloseBtn>
			</SC.Window>
		</SC.Container>
	);
};
