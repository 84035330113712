import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPlatformAPI} from '../../api/search-platform-api';
import {requestCodes} from '../../shared/constants/request-codes';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setShowLoader} from '../reducers/user-data-reducer';

export const fetchGetOrderAcceptServiceById = createAsyncThunk(
	'orderAttachSlice/fetchGetOrderAcceptServiceById',
	async (params: { id: string }, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getServiceById(params);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data};
			}
			return {};

		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
