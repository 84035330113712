import {useCallback, useState} from 'react';

import {getIsKeyOf} from '../utils/get-is-key-of';

export function useFields<T extends object>(initialData: T, defaultData = initialData) {
	const [fields, setFields] = useState(initialData);

	const onChangeField = useCallback(
		(key: string, value: unknown) => {
			if (!getIsKeyOf(fields, key)) {
				return;
			}

			setFields((filters) => ({...filters, [key]: value}));
		},
		[initialData],
	);

	const onResetFields = useCallback(() => {
		setFields(defaultData);
		return defaultData;
	}, []);

	return {
		fields,
		onChangeField,
		onResetFields,
	};
}
