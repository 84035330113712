import {useMemo} from 'react';

import {DayInfo, ScheduleInfo} from '../../interfaces/interfaces';
import {AggregatedCalendar, getWeek, REVERSED_SCHEDULE_PRIORITY, useCalendarDate} from './schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-calendary/useCalendarDate';
import {ScheduleCalendarType} from './schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-settings/components/index.type';

interface GetDayType {
	day: number;
	month: number;
	numWeek: number;
	week: number;
	year: number;
}

// TODO: remove all any types
export const findByKind = {
	[ScheduleCalendarType.ONCE_DATE]: (date: GetDayType, el: any) => { return new Date(date.year, date.month, date.day).toLocaleDateString().replaceAll('.', ' ') === el.date && el.date.replaceAll('-', ' ');},
	[ScheduleCalendarType.YEARLY_FOR_DAY]: (date: GetDayType, el: any) => { return el.month === date.month && el.day === date.day; },
	[ScheduleCalendarType.YEARLY_FOR_WEEK]: (date: GetDayType, el: any) => { return el.month === date.month && el.week === date.week && el.day === date.numWeek; },
	[ScheduleCalendarType.MONTHLY_FOR_DAY]: (date: GetDayType, el: any) => { return date.day === el.day; },
	[ScheduleCalendarType.MONTHLY_FOR_WEEK]:(date: GetDayType, el: any) => { return el.day === date.numWeek && el.week === date.week;},
	[ScheduleCalendarType.WEEKLY]: (date: GetDayType, el: any) => { return el.day === date.numWeek; },
	[ScheduleCalendarType.DAILY]:(_date: GetDayType, _el: any) => { return true; },
};

export const getWeekDay = (date: Date) => {
	const weekDay = date.getDay();
	return weekDay === 0 ? 7 : weekDay;
};


export const useGetDayInfo = (day: Date, schedule: ScheduleInfo[]) => {
	const {calendar} = useCalendarDate(schedule);
	const date = useMemo<GetDayType>(
		() => ({
			day: day.getDate() + 1,
			week: getWeek(day) + 1,
			numWeek: getWeekDay(day),
			month: day.getMonth(),
			year: day.getFullYear(),
		}),
		[day]
	);

	const dayInfo: DayInfo = useMemo(() => {
		const selectedRules = REVERSED_SCHEDULE_PRIORITY.find((kind) => {
			return calendar[kind].find((el) => findByKind[kind](date, el)) !== undefined;
		});

		if (!selectedRules) {
			return 'Weekend';
		}

		const rules = calendar[selectedRules].filter((el) => findByKind[selectedRules](date, el)).map((rule) => rule.info);

		if (rules.find((rule) => rule === 'Weekend')) {
			return 'Weekend';
		}

		return {Working: {
			schedule: rules.map((rule) => rule !== 'Weekend' ? rule.Working.schedule : []).flat()
		}};
	}, [calendar, date]);

	return {dayInfo};
};

export const getDayInfo = (calendar: AggregatedCalendar, day: Date): DayInfo => {
	const date: GetDayType = {
		day: day.getDate() + 1,
		week: getWeek(day) + 1,
		numWeek: getWeekDay(day),
		month: day.getMonth(),
		year: day.getFullYear(),
	};

	const selectedRules = REVERSED_SCHEDULE_PRIORITY.find((kind) => {
		const executeRule = calendar[kind].find((el) => findByKind[kind](date, el));
		return executeRule !== undefined;
	});

	if (!selectedRules) {
		return 'Weekend';
	}

	const rules = calendar[selectedRules].map((rule) => rule.info);

	if (rules.find((rule) => rule === 'Weekend')) {
		return 'Weekend';
	}
	return {Working: {
		schedule: rules.map((rule) => rule !== 'Weekend' ? rule.Working.schedule : []).flat()
	}};
};
