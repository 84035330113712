import {ServiceTypeKind} from '../../../interfaces/enums';
interface SERVICE_KIND_LABEL_MAP_TYPE{
	[key: string]: string
}

export const SERVICE_KIND_LABEL_MAP: SERVICE_KIND_LABEL_MAP_TYPE = {
	[ServiceTypeKind.rent]: 'Спортивные площадки',
	[ServiceTypeKind.inventory]: 'Снаряжение/инвентарь',
	[ServiceTypeKind.event]: 'Спортивные события',
	[ServiceTypeKind.tournament]: 'Турниры',
	[ServiceTypeKind.workout]: 'Тренировки',
};
export const priceTitle = (eventType: string) => {
	switch (eventType) {
		case 'Workout':
			return `Стоимость
за занятие`;
		case 'Tournament':
		case 'Event':
			return `Стоимость
участия`;
		case 'Inventory':
		case 'Rent':
			return `Стоимость
аренды`;
		default:
			return `Неверный тип
спортивного событие`;
	}
};
export const kindLabelView = (eventType: string) => {
	const labels: { [key: string]: string } = {
		[ServiceTypeKind.workout]: 'Размер группы',
		[ServiceTypeKind.tournament]: 'Количество участников',
		[ServiceTypeKind.event]: 'Количество участников',
		[ServiceTypeKind.inventory]: 'Количество',
		[ServiceTypeKind.rent]: 'Вместительность'
	};
	return labels[eventType] || 'Неверный тип спортивного события';
};
export const kindLabelViewTable = (eventType: string) => {
	const FREE_PLACE = 'Свободных мест';

	const labels: { [key: string]: string } = {
		[ServiceTypeKind.workout]: FREE_PLACE,
		[ServiceTypeKind.tournament]: FREE_PLACE,
		[ServiceTypeKind.event]: FREE_PLACE,
		[ServiceTypeKind.inventory]: 'Доступно',
		[ServiceTypeKind.rent]: 'Доступно'
	};
	return labels[eventType] || 'Неверный тип спортивного события';
};
export const kindLabelTypeView = (eventType: string) => {
	if (eventType === ServiceTypeKind.inventory) {
		return 'шт';
	}
	return 'чел';
};
