import {DayInfo, PricePolicyItem, ScheduleInfo, ScheduleItem} from '../../../interfaces/interfaces';
import {OrderStepPolicy} from '../../../interfaces/service';
import {
	SCHEDULE_PRIORITY,
	useCalendarDate
} from '../schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-calendary/useCalendarDate';
import {
	ScheduleCalendarType
} from '../schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-settings/components/index.type';

export type ServicePriceInfo = {
	minPricePerOrder: number;
	hasPriceChanges: boolean;
};

export type PriceCalcInfo = {
    from: number;
    amount: number;
    duration: number;
    defaultPrice: number;
    policy?: OrderStepPolicy;
};

const scheduleRuleFor = (schedule: ScheduleItem[], from: number): ScheduleItem | undefined => {
	return schedule.find((item) => {
		return item.start_working <= from && from < item.end_working;
	});
};

export const calculateBestPriceFor = (schedule: ScheduleItem[], info: PriceCalcInfo): number | undefined => {
	let result = 0;
	let from = info.from;
	let duration = info.duration;
	let currentRule = scheduleRuleFor(schedule, from);
	const default_policy: PricePolicyItem = {
		price: info.defaultPrice,
		duration: info.policy?.min_order_duration,
	};
	const isBestPolicy = (policy: PricePolicyItem, duration: number): boolean => {
		const policyDuration = policy.duration || 0;
		return policyDuration <= duration;
	};
	while (duration > 0 && currentRule !== undefined) {
		if (from >= currentRule.end_working) {
			currentRule = scheduleRuleFor(schedule, from);
			continue;
		}
		const pricePolicy = currentRule.property?.price_policy || [default_policy];
		const bestPricePolicy = pricePolicy.reverse().find(isBestPolicy) || default_policy;
		const policyDuration = (bestPricePolicy.duration || info.policy?.min_order_duration) ?? currentRule.end_working - from;
		const currentDuration = Math.min(policyDuration, duration);
		from += currentDuration;
		duration -= currentDuration;
		result += bestPricePolicy.price * currentDuration / policyDuration;
	}
	return result * info.amount;
};

const getPriceFor = (options: {dayInfo: DayInfo, policy?: OrderStepPolicy, defaultPrice: number}): ServicePriceInfo | undefined => {
	if (options.dayInfo === 'Weekend') {
		return;
	}
	const schedule = options.dayInfo.Working.schedule;

	const bestOrderPrice = Math.min(...schedule.map(({start_working, end_working}) => {
		return calculateBestPriceFor(schedule, {
			from: start_working,
			amount: 1,
			duration: options.policy?.min_order_duration || (end_working - start_working),
			defaultPrice: options.defaultPrice,
			policy: options.policy,
		}) || Number.MAX_SAFE_INTEGER;
	}));
	if (bestOrderPrice === Number.MAX_SAFE_INTEGER) {
		return;
	}
	const minPricePerOrder = bestOrderPrice;
	const minSchedulePrice = Math.min(...schedule.map(({property}) => {
		const prices = [...(property?.price_policy || []), {price: options.defaultPrice}];
		return Math.min(...prices.map(({price}) => price));	
	}));
	const maxSchedulePrice = Math.max(...schedule.map(({property}) => {
		const prices = [...(property?.price_policy || []), {price: options.defaultPrice}];
		return Math.max(...prices.map(({price}) => price));
	}));
	const hasPriceChanges = minSchedulePrice !== maxSchedulePrice;
	return {minPricePerOrder: Math.trunc(minPricePerOrder), hasPriceChanges};
};

export const useServicePrice = (schedule: ScheduleInfo[], policy: OrderStepPolicy | null, defaultPrice: number): ServicePriceInfo | undefined => {
	const {calendar} = useCalendarDate(schedule);
	const closestFrequencyType = SCHEDULE_PRIORITY.find((kind: ScheduleCalendarType) => {
		return calendar[kind].length !== 0;
	});

	if (!closestFrequencyType) {
		return;
	}

	const frequencyWorkingInfo = calendar[closestFrequencyType];
	const priceInfos: ServicePriceInfo[] = frequencyWorkingInfo
											.map((dayInfo) => getPriceFor({dayInfo: dayInfo.info, policy: policy ?? undefined, defaultPrice}))
											.filter((priceInfo) => priceInfo !== undefined) as ServicePriceInfo[];
	if (priceInfos.length === 0) {
		return;
	}
	return priceInfos.reduce((acc, priceInfo) => {
		return {
			minPricePerOrder: Math.min(acc.minPricePerOrder, priceInfo.minPricePerOrder),
			hasPriceChanges: acc.hasPriceChanges || priceInfo.hasPriceChanges
		};
	}, {minPricePerOrder: Infinity, hasPriceChanges: false});

};
