import {CSSProperties, FC, useState} from 'react';

import {OrganizationListItem} from '../../../../interfaces/organization';
import {
	OrganizationCardModal
} from '../../../../pages/organization-list-page/components/organization-list/components/organization-card/components/organization-card-modal/organization-card-modal';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {allOwnersOrg} from '../../../../redux/selectors/org-state-selectors';
import {useAppDispatch} from '../../../../redux/store';
import {fetchDeactivateOrg} from '../../../../redux/thunks/organization/deactivateOrg';
import {fetchGetOwnersOrg} from '../../../../redux/thunks/organization/get-owners-org-thunk';
import {fetchRecoverOrg} from '../../../../redux/thunks/organization/recover-org';
import {fetchRemoveOrg} from '../../../../redux/thunks/organization/remove-org';
import {ADMIN, ADMIN_SETTING_ORGS, UPDATE_ORG} from '../../../../shared/constants/path';
import {generatedUrlOrgName} from '../../../../shared/generated-url-org-name';
import {useResizeWindow} from '../../../../shared/hooks/use-resize-window';
import {filterRegisteredUsers} from '../../../../shared/utils/filters/filter-registred-users';
import {ConfirmDeletion} from '../../../confirm-deletion/confirm-deletion';
import {ButtonCard} from '../../button-card/button-card';
import * as SC from './button-group-setting-orgs.style';
import {OrganizationCardModalMobile} from './button-group-setting-orgs.style';

interface ButtonGroupSettingOrgsProps {
	isAdmin: boolean;
	organization: OrganizationListItem;
	style?: CSSProperties
	refetch: () => void
}

export const ButtonGroupSettingOrgs: FC<ButtonGroupSettingOrgsProps> = ({
																			style,
																			isAdmin,
																			organization,
																			refetch
																		}) => {
	const [type, setType] = useState('');
	const dispatch = useAppDispatch();
	const allOwners = useAppSelector(allOwnersOrg);
	const width = useResizeWindow();
	const isMobileMod = width <= 1299;

	const removeClickHandler = () => {
		dispatch(fetchRemoveOrg({org_name: organization.org_name})).then(() => refetch());
		setType('');
	};
	const recoverClickHandler = () => {
		dispatch(fetchRecoverOrg({org_name: organization.org_name})).then(() => refetch());
		setType('');
	};

	const deactivateClickHandler = () => {
		dispatch(fetchDeactivateOrg({org_name: organization.org_name})).then(() => refetch());
		setType('');
	};

	const ownersClickHandler = () => {
		dispatch(fetchGetOwnersOrg(organization.org_name)).then(() => setType('owners'));
	};

	return (
		<>
			{isAdmin && organization.active &&
				<SC.CardRemove style={style}>
					<SC.ButtonWrapper>
						{type === 'owners' && <OrganizationCardModal
							type={'owners'}
							allOwners={filterRegisteredUsers(allOwners)}
							handleIsType={() => setType('')}
							formType
							style={!isMobileMod ? SC.OrganizationCardModal : OrganizationCardModalMobile}/>}
						<ButtonCard title={'Все владельцы'} background={'#CB8B2A;'}
									buttonClick={ownersClickHandler}/>
					</SC.ButtonWrapper>
					<SC.LinkUpdate
						to={`${ADMIN}${ADMIN_SETTING_ORGS}${UPDATE_ORG}`.replace(':org_name', generatedUrlOrgName(organization.org_name))}>
						<ButtonCard title={'Изменить'} background={'var(--green)'}/>
					</SC.LinkUpdate>
					<SC.ButtonWrapper>
						{type === 'deactivate' &&
							<ConfirmDeletion type={'recover'} remove deleteFalse={() => setType('')}
											 deleteTrue={deactivateClickHandler}
											 style={SC.ConfirmDeletion}/>}
						<ButtonCard buttonClick={() => setType('deactivate')} title={'Деактивировать'}
									background={'#352C8D'}/>
					</SC.ButtonWrapper>
				</SC.CardRemove>
			}
			{isAdmin && !organization?.active &&
				<SC.CardRemove style={style}>
					<SC.LinkUpdate
						to={`${ADMIN}${ADMIN_SETTING_ORGS}${UPDATE_ORG}`.replace(':org_name', generatedUrlOrgName(organization.org_name))}>
						<ButtonCard title={'Изменить'} background={'var(--green)'}/>
					</SC.LinkUpdate>
					<ButtonCard buttonClick={recoverClickHandler} title={'Активировать'}
								background={'#352C8D'}/>
					<SC.ButtonWrapper>
						{type === 'remove' && <ConfirmDeletion type={type} remove deleteFalse={() => setType('')}
															   deleteTrue={removeClickHandler}
															   style={SC.ConfirmDeletion}/>}
						<ButtonCard buttonClick={() => setType('remove')} title={'Удалить'}
									background={'var(--yellow)'}/>
					</SC.ButtonWrapper>
				</SC.CardRemove>}
		</>
	);
};
