import {FC, useRef} from 'react';

import {useModalWindow} from '../../../../../../../../shared/hooks/useModalWindow';
import * as SC from './organization-card-modal.style';
import {OrganizationCardModalType} from './organization-card-modal.type';


export const OrganizationCardModal:FC<OrganizationCardModalType> = (
    {
        allOwners,
        type,
        handleIsType,
        formType,
		style,
    }) => {

	const elementRef = useRef<HTMLDivElement>(null);
	useModalWindow(elementRef, () => handleIsType());

    if (!type) {
        return null;
    }

    return (
            <SC.Container type={type === 'owners'} owners={true} style={style} ref={elementRef}>
                <SC.OwnerTitle>{formType ? 'Все владельцы клуба' : 'Все модераторы зала'}</SC.OwnerTitle>
                {allOwners.map((elem, i) =>
                    <SC.OwnerItem key={i}>
						<span>{elem.Registered.email}</span>
                    </SC.OwnerItem>)}
            </SC.Container>
    );
};
