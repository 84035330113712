import styled from '@emotion/styled';

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	&:hover{
		.logo{
			filter: brightness(0.6);
		}
		.select-btn{
			display: block;
		}
	}
`;
