import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DayInfo} from '../../interfaces/interfaces';
import {
	ListFrequencyType,
	ListRuleType,
} from '../../shared/utils/schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-settings/components/index.type';

interface ScheduleEditorSettingsRulesType {
	scheduleEditorModalSettingsDaily: ListRuleType,
	isValid: boolean
	isApply: boolean
	newPrice: number
}

const initialState: ScheduleEditorSettingsRulesType = {
	scheduleEditorModalSettingsDaily: {
		startAt: 0,
		endAt: 0,
		weekend: false,
		price: 0,
		frequency: [],
	},
	isValid: true,
	isApply: false,
	newPrice: 0,
};

export const scheduleEditorSettingsRulesSlice = createSlice({
	name: 'scheduleEditorSlice',
	initialState,
	reducers: {
		addFrequencyItem: (state: ScheduleEditorSettingsRulesType, action: PayloadAction<ListFrequencyType>) => {
			// Checking for the presence of the same object
			const exists = state.scheduleEditorModalSettingsDaily.frequency.some(item =>
				item.type === action.payload.type &&
				JSON.stringify(item.value) === JSON.stringify(action.payload.value) &&
				item.optionId === action.payload.optionId
			);
			// If the object does not exist, add it
			if (!exists) {
				return {
					...state,
					scheduleEditorModalSettingsDaily: {
						...state.scheduleEditorModalSettingsDaily,
						frequency: [
							...state.scheduleEditorModalSettingsDaily.frequency,
							action.payload,
						],
					},
				};
			}
			// If the object already exists, return the state unchanged
			return state;
		},
		removeFrequencyItem: (state: ScheduleEditorSettingsRulesType, action: PayloadAction<string>) => {
			return {
				...state, scheduleEditorModalSettingsDaily: {
					...state.scheduleEditorModalSettingsDaily,
					frequency: state.scheduleEditorModalSettingsDaily.frequency.filter(item => item.optionId !== action.payload),
				},
			};
		},
		setNewPrice: (state, action) => {
			state.newPrice = action.payload;
		},
		removeFrequency: (state) => {
			return {
				...state,
				scheduleEditorModalSettingsDaily: {...state.scheduleEditorModalSettingsDaily, frequency: []}
			};
		},
		setScheduleEditorTime: (state: ScheduleEditorSettingsRulesType, {payload}) => {
			return {
				...state,
				scheduleEditorModalSettingsDaily: {...state.scheduleEditorModalSettingsDaily, ...payload},
			};
		},
		setWeekend: (state: ScheduleEditorSettingsRulesType, action) => {
			return {
				...state,
				scheduleEditorModalSettingsDaily: {
					...state.scheduleEditorModalSettingsDaily,
					weekend: action.payload,
				},
			};
		},
		setResetForm: () => {
			return initialState;
		},
		setResetFormWithoutApply: (_, action) => {
			return {...initialState, isApply: action.payload};
		},
		setIsApply: (state: ScheduleEditorSettingsRulesType, action) => {
			state.isApply = action.payload;
		},
		setIsValid: (state: ScheduleEditorSettingsRulesType, action: PayloadAction<{
			Daily: { info: DayInfo }[],
			Weekly: { day: number, info: DayInfo }[],
			MonthlyForDay: { day: number, info: DayInfo }[],
			MonthlyForWeek: { day: number, week: number, info: DayInfo }[],
			YearlyForDay: { day: number, month: number, info: DayInfo }[]
			YearlyForWeek: { day: number, week: number, month: number, info: DayInfo }[]
			OnceDate: { date: string, info: DayInfo }[],
		}>) => {

			const validDayInfo = (info: DayInfo): boolean => {
				return info === 'Weekend' ||
					info.Working.schedule.some(
						scheduleItem => {
							return !(scheduleItem.start_working >= state.scheduleEditorModalSettingsDaily.startAt
								&& scheduleItem.end_working <= state.scheduleEditorModalSettingsDaily.endAt)
								|| scheduleItem.property && scheduleItem.property.price_policy.find(pricePolicy => pricePolicy.price === state.newPrice) !== undefined;
						});
			};
			const validScheduleItem = (frequency: ListFrequencyType): boolean => {
				switch (frequency.type) {
					case 'Daily':
						return !action.payload.Daily.some(el =>
							!validDayInfo(el.info));
					case 'Weekly':
						return !action.payload.Weekly.some(el =>
							el.day === frequency.value.week && !validDayInfo(el.info));
					case 'Monthly':
						if (frequency.value.week) {
							return !action.payload.MonthlyForWeek.some(el =>
								(el.day === frequency.value.numWeek
									&& el.week === frequency.value.week
									&& !validDayInfo(el.info)));
						}
						return !action.payload.MonthlyForDay.some(el =>
							(el.day === frequency.value.day
								&& !validDayInfo(el.info)),
						);
					case 'Yearly':
						if (frequency.value.week) {
							return !action.payload.YearlyForWeek.some(el =>
								(el.day === frequency.value.numWeek
									&& el.week === frequency.value.week
									&& el.month === frequency.value.month
									&& !validDayInfo(el.info)),
							);
						}
						return !action.payload.YearlyForDay.some(el =>
							(el.day === frequency.value.day
								&& el.month === frequency.value.month
								&& !validDayInfo(el.info)),
						);
					case 'OnceDate':
						return !action.payload.OnceDate.some(el =>
							el.date === frequency.value.anyDay && !validDayInfo(el.info),
						);
				}
				return false;
			};

			return {
				...state,
				isValid: !state.scheduleEditorModalSettingsDaily.frequency.find(frequency => !validScheduleItem(frequency))
			};
		},
	},
});
export const scheduleEditorSettings = scheduleEditorSettingsRulesSlice.reducer;
export const {
	setNewPrice,
	removeFrequency,
	setIsApply,
	addFrequencyItem,
	removeFrequencyItem,
	setResetFormWithoutApply,
	setScheduleEditorTime,
	setWeekend,
	setIsValid,
	setResetForm,
} = scheduleEditorSettingsRulesSlice.actions;
