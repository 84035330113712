import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const TextContentWrapper = styled.div`
	background-color: var(--grey);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	cursor: default;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	height: 100%;
	gap: 10px;
	line-height: 1.3em;
	padding: 6px 12px 22px;
	margin: 0 6px;
	color: var(--link-color);
`;
export const LinkMap = styled.div`
	position: absolute;
	flex-shrink: 0;
	top: 8px;
	right: 16px;
	margin-left: 5px;
	color: var(--green);
	text-decoration: underline;
	cursor: pointer;
`;
export const TooltipContainer = css`
	width: 100%;
	min-height: 42px;
	display: flex;
	align-items: center;
`;
export const TextWithIcon = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const Description = styled.p`
	-webkit-box-orient: vertical;
	display: -webkit-box;
	font-size: 14px;
	height: 60px;
	line-height: 1.385em;
	overflow: auto;
	white-space: pre-wrap;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #9f9f9f;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eaeaea;
		border-radius: 10px;
	}
`;
export const Label = styled.span`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
export const NameWrapper = styled.div`
	font-size: 16px;
	padding: 30px 12px 0;
	margin: 0 6px;
	background-color: var(--grey);
	position: relative;
`;
