import styled from '@emotion/styled';

export const FreeLesson = styled.div`
	display: flex;
	background: linear-gradient(135deg, #352c8d 0%, #0f0c27 100%);
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	padding: 12px 20px;
	width: 100%;
	max-width: 350px;
	min-width: 250px;
	height: 90px;
	cursor: pointer;
`;
export const FreeLessonText = styled.div`
	font-family: Bebas Neue, sans-serif;
	color: var(--white-color);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2;

	span {
		color: #e9a134;
	}
`;
export const FreeLessonVector = styled.img`
	width: 6px;
	height: 13px;
	rotate: 180deg;
`;
