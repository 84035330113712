import {FC, memo, useMemo} from 'react';
import {Link, NavigateOptions, useNavigate} from 'react-router-dom';

import {OrderType} from '../../../../../interfaces/enums';
import {ServiceListItem} from '../../../../../interfaces/service';
import {CounterBlock} from '../../../../../pages/order-page/components/counter-block/counter-block';
import {OrderBtnBlock} from '../../../../../pages/order-page/components/order-btn-block/order-btn-block';
import {SERVICE_ABONEMENT, SERVICE_PAGE} from '../../../../../shared/constants/path';
import {kindLabelView, priceTitle} from '../../../../../shared/constants/service/kind';
import {getPriceLabel} from '../../../../../shared/utils/converter-price';
import {useGetDayInfo} from '../../../../../shared/utils/get-day-info';
import {useServicePrice} from '../../../../../shared/utils/service/use-service-price';
import {converterTime} from '../../../../../shared/utils/time/converter-time';
import {extractUniqueTimePairs} from '../../../../../shared/utils/time/extract-unique-time-pairs';
import {getParticipantsLabel} from '../../../../../shared/utils/transform-text-label';
import {SingleSelect} from '../../../../controls';
import IconCount from '../../../../svg-icon-components/icon-count';
import IconDollar from '../../../../svg-icon-components/icon-dollar';
import IconTime from '../../../../svg-icon-components/icon-time';
import styles from '../../../../WorkSchedule/index.module.css';
import {Address} from '../../components/address/address';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {
	ServiceInfoIconTooltipWrapper
} from '../../components/service-info-icon-tooltip-wrapper/service-info-icon-tooltip-wrapper';
import {ServiceName} from '../../components/service-name/service-name';
import {TextWithIcon} from '../../components/text-with-icon/text-with-icon';
import * as SC from './new-service-card-order.style';

export interface ServiceCardProps {
	service: ServiceListItem;
	onClick?: () => void;
	checked?: boolean;
	amount?: number | null;
	onChangeAmount?: (amount: number) => void;
	isOrderPage?: boolean
	onSelect?: () => void
	isOrderMap?: boolean
}

export const NewServiceCardOrder: FC<ServiceCardProps> = memo(({
																   onSelect, service, onClick, checked, amount,
																   onChangeAmount, isOrderPage, isOrderMap
															   }) => {
	const navigate = useNavigate();
	const {dayInfo} = useGetDayInfo(new Date(), service.schedule);
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);
	const orgLogo = useMemo(()=>service.org_info.logo.find(image => image.info.size_label === '1:1')?.info.image_link, [service.id]);
	const serviceLogo = service.logo.at(0);
	const forAbonement = `Стоимость пробного
занятия`;
	const timeConv = useMemo(() =>
			dayInfo === 'Weekend'
				? ['Выходной']
				: Object.values(dayInfo.Working.schedule).map(el =>
					`${converterTime(el.start_working)}-${converterTime(el.end_working)}`)
		, [dayInfo]);

	const uniqueTime =
		extractUniqueTimePairs([...new Set(timeConv)]);
	const goToServiceCard = (options?: NavigateOptions) => {
		navigate(SERVICE_PAGE.replace(':page', service.id), options);
	};
	const goToServiceCardMap = () => {
		goToServiceCard({state: {map: true}});
	};
	const onClickHandler = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<CardContainer>
			<Logo orgLogo={orgLogo} serviceLogo={serviceLogo?.info.image_link} serviceName={service.service_type}
				  orgName={service.org_info.org_name} checked={checked} onClick={onClickHandler}/>
			<SC.TextContentWrapper checked={checked} page={!!isOrderPage || !!isOrderMap}>
				<ServiceInfoIconTooltipWrapper service={service} checked={checked} onGoToMap={goToServiceCardMap}/>
				<ServiceName serviceName={service.service_type} checked={checked}/>
				<Address platformInfo={service.platform_info} goToServiceCardMap={goToServiceCardMap}/>
				{service.order_policy.allows !== OrderType.abonementOnly ?
					<TextWithIcon icon={<IconDollar/>} description={priceTitle(service.service_kind)}
								  textRight={priceInfo ? getPriceLabel(priceInfo.minPricePerOrder, priceInfo.hasPriceChanges) : 'Не определена'}/>
					: <SC.AbonementPriceWrapper>
						<SC.IconPriceWrapper>
							<IconDollar/>
							<span>{forAbonement}</span>
						</SC.IconPriceWrapper>
						{service.order_policy.trial_order === null ? <Link
							to={SERVICE_ABONEMENT.replace(':kind', service.service_kind).replace(':service_id', service.id)}>Предложения</Link>
						: `${(service.order_policy.trial_order / 100).toFixed(2)} BYN`}
					</SC.AbonementPriceWrapper>}
				<SC.TimeWrapper>
					<span>
						<IconTime/>
						{uniqueTime[0].trim() === 'Выходной -' ?
							<span>Выходной</span> : (uniqueTime.length === 1
								? <span>{uniqueTime[0]}</span>
								: <SingleSelect
									containerClassName={styles.selector_container}
									options={uniqueTime.map((time) => ({
										value: time,
										label: time,
									}))}
									value={uniqueTime[0]}
								/>)}
					</span>
					<span>Сегодня</span>
				</SC.TimeWrapper>
				<TextWithIcon icon={<IconCount/>} description={kindLabelView(service.service_kind)}
							  textRight={getParticipantsLabel(service.max_participants, service.service_kind)}/>
			</SC.TextContentWrapper>
			{(checked && isOrderPage) && <CounterBlock
				count={amount || 0}
				maxCount={service.max_participants}
				onCountChange={onChangeAmount}
			/>}
			{(checked && isOrderMap && <OrderBtnBlock onBtnClick={onSelect}/>)}
		</CardContainer>
	);
});
