import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {OrganizationSingle} from '../../interfaces/organization';
import {fetchGetOrganizationByName} from '../thunks/organization/get-organization-by-name-thunk';

interface InitialStateSchedulePage {
selectOrg : OrganizationSingle | null;
}

const initialState: InitialStateSchedulePage = {
	selectOrg: null
};

export const schedulePageSlice = createSlice({
	name: 'schedule-page',
	initialState,
	reducers: {
		setSelectOrg: (state: InitialStateSchedulePage, action: PayloadAction<null>) => {
			state.selectOrg = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchGetOrganizationByName.fulfilled, (state, action) => {
			state.selectOrg = action.payload?.data;
		});
	}
});
export const schedulePage = schedulePageSlice.reducer;
export const {setSelectOrg} = schedulePageSlice.actions;
