import classNames from 'classnames';
import {InputMask, InputMaskProps} from 'primereact/inputmask';
import React, {forwardRef, JSX, memo} from 'react';

import {FieldBase, IconPosition} from '../field-base/field-base';
import styles from './phone-input.module.css';

interface PhoneInputProps extends InputMaskProps {
	icon?: JSX.Element;
	iconPosition?: `${IconPosition}`;
	containerClassName?: string;
	labelClassName?: string;
	label?: string;
	appearance?: 'error';
}

const PhoneInput = forwardRef<InputMask, PhoneInputProps>(function ForwardPhoneInput(
	{icon, iconPosition, containerClassName, labelClassName, appearance, label, id, ...restProps},
	ref,
) {
	return (
		<div className={styles.input_wrapper}>
			{label && (
				<label htmlFor={id} className={classNames(styles.label, labelClassName)}>
					{label}
				</label>
			)}
			<FieldBase icon={icon} iconPosition={iconPosition} className={classNames(containerClassName, styles[`appearance_${appearance}`])}>
				<InputMask
					{...restProps}
					className={classNames(styles.input, restProps.className)}
					mask="+375 (99) 999-99-99"
					slotChar="x"
					autoComplete="on"
					autoClear={false}
					required={true}
					ref={ref}
				/>
			</FieldBase>
		</div>
	);
});

export default memo(PhoneInput);
