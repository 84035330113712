import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setCheckedPlatform} from '../../reducers/platforms-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetPlatformById = createAsyncThunk(
	'fieldSlice/fetchGetPlatformById',
	async (data: string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getPlatformById(data);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				dispatch(setCheckedPlatform({data: response.data}));
				return {status: response.status, data: response.data};
			}
			if (response.status === requestCodes.emptyData) {
				dispatch(setCheckedPlatform({data: {}}));
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			return handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
