import {FC, useEffect, useRef} from 'react';
import Flickity from 'react-flickity-component';

import {UniqueImage} from '../images/index.type';
import * as SC from './flickity-slider.style';

interface FlickitySliderProps {
	images: UniqueImage[];
	showImgId: string;
	slickShowSlider: () => void;
}

export const FlickitySlider: FC<FlickitySliderProps> = ({images, showImgId, slickShowSlider}) => {
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
				slickShowSlider();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [slickShowSlider]);

	const flickityOptions = () => {
		const initialIndex = images.findIndex(image => image.id === showImgId);
		return {
			initialIndex,
		};
	};

	return (
		<SC.FlickityContainer>
			<SC.FlickityWrapper ref={elementRef}>
				<Flickity
					className={SC.Slider}
					elementType="div"
					disableImagesLoaded={false}
					options={flickityOptions()}
					reloadOnUpdate
					static
				>
					{images.map((item, index) => {
						return (
							<div key={index}>
								<SC.Images key={index} src={item.info.image_link}
										   alt="фотографии спортивного клуба" />
							</div>
						);
					})}
				</Flickity>
			</SC.FlickityWrapper>
		</SC.FlickityContainer>
	);
};
