import {FC, memo} from 'react';

import vector from '../../../assets/icon/arrow_white.svg';
import * as SC from './free-lesson.style';

interface Props {
	selected: boolean;
	trialOrderService: number;
	changeTrial: ()=> void;
}

export const FreeLesson:FC<Props> = memo(({selected, trialOrderService, changeTrial}) => {
	return (
		<SC.FreeLesson onClick={changeTrial}>
			{!selected && trialOrderService === 0 && <SC.FreeLessonText>Хочу <span>бесплатное</span> пробное занятие</SC.FreeLessonText>}
			{!selected && trialOrderService !== 0 && <SC.FreeLessonText>Хочу пробное занятие</SC.FreeLessonText>}
			{selected && <SC.FreeLessonText>Заказ будет оформлен как пробное занятие</SC.FreeLessonText>}
			<SC.FreeLessonVector src={vector} alt="vector"/>
		</SC.FreeLesson>
	);
});
