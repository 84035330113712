import styled from '@emotion/styled';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999999;
`;
export const Window = styled.div`
	width: 100%;
	max-width: 900px;
	height: 100%;
	max-height: 60vh;
	min-height: 50vh;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white-color);
	border-radius: 6px;
	padding: 60px 20px;
	border: 1px solid var(--gray-middle);
	@media screen and (max-width: 800px){
		background-color: var(--border-input);
	}
`;
export const FormButton = styled.div`
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
`;

export const TextInput = styled.span`
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 20px;
	vertical-align: middle;
	color: var(--white-color);
	text-align: center;
	border-radius: 4px;
	background-color: var(--purple);
	line-height: 22px;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
	transition: 0.5s;
	text-wrap: nowrap;

	&:hover {
		background-color: var(--primary-color);
	}
`;
export const CloseBtn  = styled.button`
	position: absolute;
	width: 30px !important;
	height: 30px !important;
	padding: 4px;
	font-weight: 600;
	right: 10px;
	top: 10px;
	font-size: 1.4rem;
	color: var(--grey-color);
	border: 1px solid var(--grey-color);
	border-radius: 50%;
	cursor: pointer;
	transition: 0.7s ease-in-out;
	&:hover {
		color: var(--blue-color);
		border: 1px solid var(--blue-color);
	}
`;
export const Img = styled.img`
	width: 200px;
	object-fit: cover;
	aspect-ratio: 16/9;
	border: 1px solid var(--gray-middle);
	cursor: pointer;
`;
export const ImagesWrapper = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	margin: 0 auto;
`;
