// for dev
// export const API_KEY_YMAPS = '8f3e58a6-a777-43d1-ae4a-ec18d6e148f2';
// for prod
export const API_KEY_YMAPS = '4594f80e-de03-4123-be65-3c99ccb08eb7';
export const TYPE_PACKAGE = 'package.full';
export const DEFAULT_COORDS = [53.9, 27.56]; // Minsk
export const DEFAULT_CITY = 'Minsk';
export const SIZE_PLACEMARK = 12;
export const SIZE_CLUSTER = 36;
export const DEFAULT_ZOOM = 17;
export const ZOOM_ALL_CITIES = 13;
