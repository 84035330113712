import './calendar.css';

import classNames from 'classnames';
import {ru} from 'date-fns/locale';
import {FC} from 'react';
import {DayPicker, DayPickerProps} from 'react-day-picker';

import IconArrowDown from '../../svg-icon-components/icon-arrow-down';
import styles from './calendar.module.css';
import {MAX_YEAR, MIN_YEAR} from './constants';

// TODO: fix styles with new design
export const Calendar: FC<DayPickerProps> = (props) => {
	return (
		<DayPicker
			styles={{
				caption: {
					textTransform: 'capitalize',
					fontSize: '16px',
					color: 'grey',
					padding: '20px 0 10px',
				},
			}}
			components={{
				IconDropdown: (props) => (
					<IconArrowDown {...props} className={classNames(styles.icon_dropdown, props.className)} />
				),
				IconRight: (props) => (
					<IconArrowDown {...props} className={classNames(styles.icon_right, props.className)} />
				),
				IconLeft: (props) => (
					<IconArrowDown {...props} className={classNames(styles.icon_left, props.className)} />
				),
			}}
			locale={ru}
			weekStartsOn={1}
			captionLayout="dropdown-buttons"
			fromYear={MIN_YEAR}
			toYear={MAX_YEAR}
			{...props}
			defaultMonth={(props.selected as Date) || new Date()}
		/>
	);
};
