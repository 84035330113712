import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const CardRemove = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	align-self: flex-end;
	width: 100%;
	margin: 0;
	gap: 10px;
	padding: 10px;
	@media screen and (max-width: 1130px){
		flex-direction: row;
	}
	button {
		border: none;
		color: #FFFFFF;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		border-radius: 5px;
		padding: 5px 9px;
		cursor: pointer;
		margin: 0;
		width: 100%;
	}
`;
export const ConfirmDeletion = {
	top: '-84px',
};
export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
	button{
		width: 100%;
	}
`;
export const OrganizationCardModal = {
	bottom: '40px',
	left: '-136px',
};
export const OrganizationCardModalMobile = {
	left: 0,
	bottom: '40px',
};
export const LinkUpdate = styled(Link)`
	width: 100%;
`;
