import {kindLabelTypeView} from '../constants/service/kind';

export function getWeeksLabel(count: number) {
	const lastDigit = count % 10;
	const lastTwoDigits = count % 100;

	if (lastDigit === 1 && lastTwoDigits !== 11) {
		return 'неделя';
	} else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
		return 'недели';
	}
	return 'недель';
}

export function getMonthsLabel(count: number) {
	const lastDigit = count % 10;
	const lastTwoDigits = count % 100;

	if (lastDigit === 1 && lastTwoDigits !== 11) {
		return 'месяц';
	} else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
		return 'месяца';
	}
	return 'месяцев';
}

export function getCountLabel(count: number): string {
	if (count === 1) {
		return 'занятие';
	} else if (count >= 2 && count <= 4) {
		return 'занятия';
	}
		return 'занятий';

}



export function getParticipantsLabel(max_participants: number, service_kind?: string): string {
	const unlimited = max_participants >= 100;
	if (unlimited) {
		return 'Неограничено';
	}
	return service_kind ? `${max_participants} ${kindLabelTypeView(service_kind)}` : max_participants.toString();
}
