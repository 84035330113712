import cnBind from 'classnames/bind';
import {FC, JSX, memo, useCallback, useMemo, useRef} from 'react';

import {useBooleanState} from '../../../shared/hooks/useBooleanState';
import {useModalWindow} from '../../../shared/hooks/useModalWindow';
import {Input} from '../../controls';
import styles from '../../data-list/DataList.module.css';

const cx = cnBind.bind(styles);

export interface DataListType {
	placeholder: string;
	options: string[];
	valueChange: string;
	onChange: (event: string) => void;
	icon?: JSX.Element
}

export const FilterWithInput: FC<DataListType> = memo(({valueChange, placeholder, options, onChange, icon}) => {
	const {value, setTrue, setFalse} = useBooleanState(true);
	const elementRef = useRef<HTMLDivElement>(null);
	useModalWindow(elementRef, () => setTrue());

	const handleChange = useCallback((e: string) => {
		onChange(e);
	}, [valueChange]);

	const filter = useMemo(() => options
		? options.filter(el => el.toLowerCase().includes(valueChange.toLowerCase()))
		: options, [options, valueChange]);

	return (
		<div ref={elementRef} className={cx('container')}>
			<Input
				icon={icon}
				iconPosition="start"
				labelClassName={cx('label')}
				type="text"
				value={valueChange}
				onFocus={() => setFalse()}
				onChange={(e) => handleChange(e.target.value)}
				placeholder={placeholder}
				className={cx('input')}
				containerClassName={cx('form__item-pad')}
			/>
			<div ref={elementRef} className={cx('items__container', 'items__general', value && 'hidden')}>
				{filter.length > 0 && <div className={cx('items')}>
					{filter.map((elem, i) => {
						return <div onClick={() => onChange(elem)} key={i} className={cx('item')}>
							<span onClick={() => setTrue()}>{elem}</span>
						</div>;
					})}
				</div>}
				{!filter.length && <div className={cx('items')}>Ничего не найдено</div>}
			</div>
		</div>
	);
});
